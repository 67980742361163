.serv-container {
  display: flex;
  background-color: var(--dark-blue);
  gap: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.serv-wrapper {
  background-color: rgb(125, 233, 98);

  position: relative;
  /* box-shadow: 0 0 15px #1eff62; */
  box-shadow: 0 0 15px #1eff6254;
}

.serv-wrapper :hover {
  box-shadow: 0 0 60px #1eff62;
  transition: 200ms;
}

.serv-wrapper ::before {
  content: "";
  position: absolute;
  inset: 3px;
  background-color: var(--dark-blue);

  z-index: -1;
}

.serv {
  color: white;
  padding: 20px;
  width: 330px;
  height: 350px;
  transition: 1s;
  position: relative;
  z-index: 100;
  // background-color: red;
}

.serv:hover {
  box-shadow: none;
}

.itag {
  position: absolute;
  inset: 0;
  display: block;
}

.itag::before {
  content: "";
  position: absolute;
  top: 0;
  left: 80%;
  height: 4px;
  width: 10px;
  background-color: var(--dark-blue);

  transform: translateX(-50%) skewX(325deg);
  transition: 500ms;
}

.serv-wrapper :hover .itag::before {
  width: 30px;
  left: 20%;
}

.itag::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 20%;
  height: 4px;
  width: 10px;
  background-color: var(--dark-blue);
  // background-color: red;

  transform: translateX(-50%) skewX(325deg);
  transition: 500ms;
}

.serv-wrapper :hover .itag::after {
  width: 30px;
  left: 80%;
}

.serv-head {
  text-transform: uppercase;
  transition: 500ms;
}

.serv-wrapper :hover .serv-head {
  letter-spacing: 1.5px;
}

.serv-items {
  text-transform: capitalize;
  font-size: 1.1rem;
}

.serv-list {
  margin-top: 15px;
}
