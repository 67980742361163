.disclaimer-container {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

.disclaimer-heading {
  color: var(--dark-blue);

  font-weight: bold;
  font-size: 2rem;
}

.disclaimer-text {
  color: var(--text-color);
}
