.brand-container {
  display: flex;
  flex-direction: row;
}

.brand-logo {
  max-width: 40px;
  max-height: 40px;
}

.realm,
.alpha {
  font-size: 1.2em;
  font-weight: 700;
  text-transform: uppercase;
}

.realm {
  color: var(--realm-blue);
  margin-right: 5px;
}

.alpha {
  color: var(--alpha-green);
}
