:root {
  --white: #ffffff;
  --realm-blue: #0054a5;
  --alpha-green: #23a000;
  --dark-blue: #0b0b44;
  // --dark-blue: #20205b;
  --text-green: #0ec339;
  --market-green: #12e200;
  --market-red: #e26d00;
  --text-color: rgb(68, 68, 68);
  $ptimary: #23a000;
}

// .App {
//   // overflow: hidden;
// }

.App-header {
  top: 0;
  z-index: 10000;
}

.app-body {
  position: relative;
  height: calc(100dvh - 82px - 73px);
  // min-width: none;
  min-height: calc(100dvh - 82px - 73px);
  max-height: calc(100dvh - 82px - 73px);
  overflow: scroll;
}
