.footer-container {
  position: -webkit-sticky; /* Safari */
  position: fixed;
  bottom: 0;
  display: flex;
  background-color: var(--dark-blue);
  width: 100vw;
  z-index: 10000;
  padding: 5px 10px 5px 10px;
  height: 75px;
  align-items: center;
}

.contacts {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  color: white;
  flex: 1;
}

.email,
.phone {
  display: flex;
}

.contact-label {
  margin: 0;
}

.contact-link {
  all: unset;
  color: white;
}

.arn {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  color: white;
  flex: 1;
  align-items: end;
}

.arn-no,
.arn-velidity {
  display: flex;
}

.arn-label {
  margin: 0;
}

.arn-value {
  margin: 0;
}
