.home-container {
  display: flex;
  flex-direction: column;
  /* max-height: 100vh; */
  // max-height: calc(100vh - 82px);
  // overflow: scroll;
}

.tagline {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* max-height: 50%; */
  // max-height: 40vh;
  // min-height: 40vh;
  padding: 10px;
  justify-content: center;
  align-items: center;
  padding-bottom: 0;
}

.tag-logo {
  padding-top: 3rem;
  height: 10rem;
}

.tagline-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tag1,
.tag2 {
  font-family: "Inria Serif";
  font-size: 2.5rem;
  text-align: center;
  margin: 0;
}

.tag1 {
  color: var(--alpha-green);
}

.tag2 {
  color: var(--realm-blue);
}

.market-ticker {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background-color: var(--dark-blue);
}

.fundexpert {
  display: flex;
  background-color: var(--dark-blue);
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.fundexpert-btn {
  position: relative;
  z-index: 1000;
  // bottom: -10px;
  background-color: var(--text-green);
  border: none;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  color: var(--white);
  font-weight: bold;
  box-shadow: -2px 6px 17px 3px rgba(0, 0, 0, 0.75);
  margin-top: 10px;
}

.services {
  flex: 1;
  background-color: var(--dark-blue);

  // max-height: calc(60vh - 82px);
  // height: 100%;
}

.services-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 5vh;
  justify-content: center;
  min-height: calc(60vh);
  min-height: calc(60vh - 150px);

  /* align-items: center; */
}

.service-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  /* margin-bottom: 20px; */
}

.service-head {
  color: var(--text-green);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.5rem;
  padding-bottom: 10px;
  text-align: center;
}

.service-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-item {
  color: var(--white);
  text-transform: uppercase;
  margin-bottom: 6px;
  font-size: 1rem;
  // font-style: italic;
}

// .contact-wrapper {
//   display: flex;
//   margin-bottom: 10px;
// }

// .contact-head {
//   color: white;
//   font-weight: bold;
//   margin-bottom: 10px;
// }

// .phone {
//   margin-right: 20px;
// }

// .contact-label {
//   color: white;
//   margin: 0;
// }

// .link {
//   all: unset;
//   color: white;
// }

@media screen and (min-width: 992px) {
  .service-wrap {
    align-items: unset;
  }
  .service-list {
    align-items: unset;
  }
}
