// Most of the styling comes from sipcalc.scss
.fdcalc-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 992px) {
  .fdcalc-container {
    min-height: 100%;
    justify-content: center;
  }
}
