.sipcalc-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sipcalc-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
}

.sipcalc-range {
  color: aqua;
  --mdb-range-thumb-background: rgb(102, 190, 19);
}

.blue-background {
  display: flex;
  width: 100%;
  background-color: (var(--dark-blue));
  min-height: 40vh;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.sip-heading {
  color: var(--white);
  text-align: center;
  padding: 20px 0px 20px 0px;
  font-size: 1.5em;
  font-weight: bold;
  width: 100vw;
  background-color: (var(--dark-blue));
}

.sip-input-box {
  display: flex;
}

.sipcalc-input {
  color: var(--text-green) !important;
  font-weight: bold !important;
  background-color: var(--white) !important;
  // max-width: 20%;
  min-width: 100px;

  border: none;
  padding: 0 7px 0 7px;
}

.sip-label {
  color: var(--white);
  width: 100%;
}

.sip-result {
  display: flex;
  flex-direction: column;
  align-self: baseline;
  justify-content: center;
  padding-top: 20px;
}

.output-label {
  color: var(--realm-blue);
  margin: 0;
}

.result {
  color: var(--text-green);
  font-weight: bold;
  font-size: 1.5rem;
}

@media screen and (min-width: 992px) {
  .sipcalc-container {
    min-height: 100%;
    justify-content: center;
  }

  .sip-heading {
    color: var(--dark-blue);
    background-color: var(--white);
  }

  .sipcalc-card {
    flex-direction: row;
    -webkit-box-shadow: 1px -2px 18px 1px rgba(0, 0, 0, 0.233);
    -moz-box-shadow: 1px -2px 18px 1px rgba(0, 0, 0, 0.233);
    box-shadow: 1px -2px 18px 1px rgba(0, 0, 0, 0.233);
  }

  .blue-background {
    flex: 1;
    // position: absolute;
    // min-width: 50vw;
    min-height: 40vh;
    padding-left: 50px;
    padding-right: 50px;

    // height: 100%;
  }

  .sip-result {
    flex: 1;

    // position: absolute;
    height: 40vh;
    left: 50vw;
    padding-right: 50px;
    padding-left: 50px;
    align-self: center;
  }
}
